import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useMediaQuery } from '@mui/material';
import { ThemeOptions, createTheme, ThemeProvider } from '@mui/material/styles';
import palette from '@theme/palette';
import typography from '@theme/typography';
import breakpoints from '@theme/breakpoints';
import shadows, { customShadows } from '@theme/shadows';
import shape from '@theme/shape';
import { merge } from 'lodash';
import componentsOverride from '@theme/overrides';
import setup from '@theme/variants/setup';
import { pxToRem } from '@helpers/common';

const Theme: React.FC<{ location: Location }> = ({ children, location }) => {
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
	const { darkMode } = useSelector(({ darkMode }) => ({
		darkMode,
	}));

	const isLight = darkMode === null ? !prefersDarkMode : !darkMode;
	const themeDirection = 'ltr';

	const themeOptions: ThemeOptions = {
		palette: isLight ? { ...palette.light, mode: 'light' } : { ...palette.dark, mode: 'dark' },
		shape,
		typography,
		breakpoints,
		direction: themeDirection,
		shadows: isLight ? shadows.light : shadows.dark,
		customShadows: isLight ? customShadows.light : customShadows.dark,
	};

	const _theme = createTheme({ ...themeOptions });
	const themes: {
		match: RegExp;
		theme: { [x: string]: any };
	}[] = [
		{
			match: /^\/dashboard/,
			theme: {
				...themeOptions,
			},
		},
		{
			match: /^\/admin/,
			theme: {
				...themeOptions,
			},
		},
		{
			match: /^\/setup/,
			theme: {
				...themeOptions,
				...setup(_theme),
			},
		},
		{
			match: /^\//,
			theme: {
				...themeOptions,
				breakpoints: {
					values: {
						md: 1200,
					},
				},
				components: {
					MuiButton: {
						styleOverrides: {
							root: {
								paddingTop: pxToRem(14),
								paddingBottom: pxToRem(14),
								paddingLeft: pxToRem(40),
								paddingRight: pxToRem(40),
							},
						},
					},
				},
			},
		},
	];

	const customTheme = themes.find((theme) => theme.match.test(location.pathname))?.theme ?? {};
	const theme = createTheme(
		merge(
			themeOptions,
			customTheme?.palette?.mode === 'dark'
				? {
						palette: palette.dark,
						shadows: shadows.dark,
						customShadows: customShadows.dark,
				  }
				: {},
			customTheme
		)
	);
	theme.components = merge(componentsOverride(theme), customTheme?.components ?? {});

	return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

export default Theme;
