import { css } from '@emotion/react';

export default (theme) => css`
	&.layout {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;

		&.layout-center {
			.content {
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				flex-grow: 1;
			}
		}

		.content-wrapper {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			background-color: ${theme.palette.mode === 'light'
					? theme.palette.grey['100']
					: theme.palette.grey['800']}
				.content {
				flex-grow: 1;
				padding: ${theme.spacing(0, 0)};

				&.content-disable-gutter-bottom {
					padding-bottom: 0;
				}

				&.content-disable-gutter-top {
					padding-top: 0;
				}
			}
		}
	}
`;
