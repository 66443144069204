import React, { useEffect } from 'react';
import styles from './styles';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import { useSnackbar } from '@components/common/Snackbar';
import { useDispatch, useSelector } from 'react-redux';
import { UserState } from '@store/user/types';
import { makePostRequest } from '@helpers/requests';
import { AUTH_PASSWORD_RESET_COMPLETE } from '@helpers/api';
import { Field, Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Button, CircularProgress, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { TextField } from 'formik-mui';
import queryString from 'query-string';
import { loginUser } from '@store/user/actions';
import { useTheme } from '@mui/material';
import Layout from '@components/common/Layouts/Landing';

const ForgotPassword: React.FC = () => {
	const theme = useTheme();
	const location: any = useLocation();
	const params = queryString.parse(location.search);
	const { id, code } = params;
	const [openSnackbar] = useSnackbar();
	const dispatch = useDispatch();

	const { user } = useSelector(({ user }) => ({ user }));

	useEffect(() => {
		if (!!user) {
			openSnackbar(`You're already logged in.`, 'error');
		}

		if (!id || !code) {
			openSnackbar(`An error occurred attempting to reset your password.`, 'error');
			navigate('/login');
		}
	}, []);

	useEffect(() => {
		if (!!user) navigate('/');
	}, [user]);

	const handleSubmit = async ({ password }) => {
		try {
			const { data: userData } = await makePostRequest(AUTH_PASSWORD_RESET_COMPLETE, {
				id,
				code,
				password,
			});
			const { payload }: any = await dispatch(loginUser(userData));
			console.log({ payload });
			openSnackbar(
				`You have successfully reset your password for ${payload?.user?.email}.`,
				'success'
			);
		} catch (error) {
			openSnackbar(
				error?.errorMessage ??
					`An error occurred attempting to complete resetting your password.`,
				'error'
			);
		}
	};

	const initialValues: { password: string; confirmPassword: string } = {
		password: '',
		confirmPassword: '',
	};

	if (!id || !code) return null;

	return (
		<Layout center>
			<Formik
				initialValues={initialValues}
				validationSchema={Yup.object().shape({
					password: Yup.string().required('Password is required'),
					confirmPassword: Yup.string()
						.oneOf([Yup.ref('password')], 'Passwords do not match.')
						.required('Confirm your password.'),
				})}
				onSubmit={handleSubmit}
			>
				{({ isSubmitting }) => (
					<Grid
						css={styles(theme)}
						container
						direction="column"
						alignItems="center"
						justifyContent="center"
					>
						<Grid item>
							<Form className="form">
								<Typography component="h1" variant="h5">
									Reset your password
								</Typography>
								<Field
									component={TextField}
									name="password"
									type="password"
									label="New password"
									fullWidth
									margin="normal"
								/>
								<Field
									component={TextField}
									name="confirmPassword"
									type="password"
									label="Confirm new password"
									fullWidth
									margin="normal"
								/>

								<Button
									type="submit"
									className="submit"
									fullWidth
									variant="contained"
									disabled={isSubmitting}
									startIcon={
										isSubmitting ? (
											<CircularProgress color="inherit" size={20} />
										) : undefined
									}
								>
									Submit
								</Button>
							</Form>
						</Grid>
					</Grid>
				)}
			</Formik>
		</Layout>
	);
};

export default ForgotPassword;
