import {
	NAVIGATION_LANDING_OPEN,
	NAVIGATION_OPEN,
	NAVIGATION_SET_MODE,
	NavigationTypes,
} from './types';

export const navigationOpen = (boolean: boolean): NavigationTypes => {
	return {
		type: NAVIGATION_OPEN,
		payload: boolean,
	};
};

export const navigationLandingOpen = (boolean: boolean): NavigationTypes => {
	return {
		type: NAVIGATION_LANDING_OPEN,
		payload: boolean,
	};
};

export const setNavigationMode = (mode: 'standard' | 'admin'): NavigationTypes => {
	return {
		type: NAVIGATION_SET_MODE,
		payload: mode,
	};
};
