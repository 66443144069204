import { css } from '@emotion/react';
import { Theme, alpha } from '@mui/material';

export default (theme: Theme) => css`
	&.navigation {
		.navigation-item {
			min-height: ${theme.spacing(6)};
			position: relative;
			text-transform: capitalize;
			padding-left: ${theme.spacing(5)};
			padding-right: ${theme.spacing(2.5)};

			&:before {
				top: 0;
				right: 0;
				width: 3px;
				bottom: 0;
				content: '';
				display: none;
				position: absolute;
				border-top-left-radius: ${theme.shape.borderRadius};
				border-bottom-left-radius: ${theme.shape.borderRadius};
				background-color: ${theme.palette.primary.main};
			}

			&.navigation-item-active {
				background-color: ${alpha(
					theme.palette.primary.main,
					theme.palette.action.selectedOpacity
				)};

				&,
				.navigation-item-icon {
					color: ${theme.palette.primary.main};
				}

				&:before {
					display: block;
				}
			}

			.navigation-item-text-primary,
			.navigation-item-text-secondary {
				font-size: ${theme.typography.fontSize}px;
			}

			.navigation-item-text-primary {
				color: ${theme.palette.text.primary};
				font-weight: ${theme.typography.fontWeightBold};
			}

			.navigation-item-text-secondary {
				color: ${theme.palette.text.secondary};
			}

			.navigation-item-icon {
				width: 22px;
				height: 22px;
				display: flex;
				align-items: center;
				justify-content: center;
				color: ${theme.palette.text.secondary};
			}
		}
	}
`;
