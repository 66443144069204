import { bakeLocalStorage, readLocalStorage } from '@helpers/storage';
import {
	NAVIGATION_OPEN,
	NAVIGATION_LANDING_OPEN,
	NAVIGATION_SET_MODE,
	NavigationState,
} from './types';

export default function navigationReducer(
	state = {
		open: readLocalStorage('navigation_open'),
		open_landing: readLocalStorage('navigation_landing_open'),
		mode: readLocalStorage('navigation_mode') || 'standard',
	},
	action: any
): NavigationState {
	// Check to see what type of action is being fired
	switch (action.type) {
		case NAVIGATION_OPEN:
			bakeLocalStorage('navigation_open', action.payload);
			return { ...state, open: action.payload };
		case NAVIGATION_LANDING_OPEN:
			bakeLocalStorage('navigation_landing_open', action.payload);
			return { ...state, open_landing: action.payload };
		case NAVIGATION_SET_MODE:
			bakeLocalStorage('navigation_mode', action.payload);
			return { ...state, mode: action.payload };
		default:
			// Return state by default if nothing else is met
			return state;
	}
}
