export const NAVIGATION_OPEN = 'NAVIGATION_OPEN';
export const NAVIGATION_LANDING_OPEN = 'NAVIGATION_LANDING_OPEN';
export const NAVIGATION_SET_MODE = 'NAVIGATION_SET_MODE';

export type NavigationState = {
	open: boolean | null;
	open_landing: boolean | null;
	mode: 'standard' | 'admin';
};

interface NavigationOpenAction {
	type: typeof NAVIGATION_OPEN;
	payload: boolean;
}

interface NavigationLandingOpenAction {
	type: typeof NAVIGATION_LANDING_OPEN;
	payload: boolean;
}

interface NavigationModeAction {
	type: typeof NAVIGATION_SET_MODE;
	payload: 'standard' | 'admin';
}

export type NavigationTypes =
	| NavigationOpenAction
	| NavigationModeAction
	| NavigationLandingOpenAction;
