export const API_ROUTE =
	process.env.GATSBY_API_URL ||
	(process.env.GATSBY_STAGING
		? 'https://staging-api.wavecard.io'
		: process.env.NODE_ENV === 'development'
		? 'http://localhost:3001'
		: 'https://api.wavecard.io');

export const PUBLIC_URL =
	process.env.NODE_ENV === 'development' ? 'http://localhost:8000' : 'https://wavecard.io';

export const CDN_LINK = process.env.CDN_LINK || `https://d1os7qfbyynk70.cloudfront.net`;

export const PYTHON_ROUTE = 'https://wavecard-python.herokuapp.com';
export const PYTHON_CARD = (id: string) => `${PYTHON_ROUTE}/get/${id}`;
export const AWS_ROUTE = 'https://wavecard.s3.amazonaws.com';
export const AWS_CARD = (id: string) => `${AWS_ROUTE}/drawings/${id}.dxf`;

export const USER_REGISTER = API_ROUTE + '/auth/register';
export const USER_LOGIN = API_ROUTE + '/auth/login';
export const USER_LOGIN_LEGACY = `${API_ROUTE}/auth/login/legacy`;
export const USER_LOGOUT = API_ROUTE + '/auth/logout';
export const USER_REFRESH = API_ROUTE + '/auth/refresh';
export const USER_EXISTS = `${API_ROUTE}/auth/existing`;

export const LINK_HUBS = `${API_ROUTE}/linkHub`;
export const LINK_HUB = (id?: string) => `${API_ROUTE}/linkHub/${id}`;
export const LINK_HUB_BACKGROUND = `${API_ROUTE}/linkHub/backgroundUpload`;
export const LINK_HUB_AVATAR = `${API_ROUTE}/linkHub/avatarUpload`;

export const LINKS = `${API_ROUTE}/linkHub/link/`;
export const LINKS_REORDER = `${API_ROUTE}/linkHub/link/all`;
export const LINK = (id?: string) => `${API_ROUTE}/linkHub/link/${id}`;

export const AUTH_PASSWORD_REPLACE = API_ROUTE + '/auth/password/replace';
export const AUTH_PASSWORD_RESET = `${API_ROUTE}/auth/password/reset/request`;
export const AUTH_PASSWORD_RESET_COMPLETE = `${API_ROUTE}/auth/password/reset/complete`;
export const AUTH_EMAIL_VERIFY = `${API_ROUTE}/auth/email/verify`;
export const AUTH_EMAIL_VERIFY_RESEND = `${API_ROUTE}/auth/email/resendVerify`;
export const AUTH_EMAIL_CONCIERGE_SETUP = `${API_ROUTE}/auth/email/conciergeSetup`;
export const AUTH_EMAIL_CHANGE_REQUEST = `${API_ROUTE}/auth/email/change/request`;
export const AUTH_EMAIL_CHANGE_COMPLETE = `${API_ROUTE}/auth/email/change/complete`;

export const USER_PROFILE_PUBLIC = (cardId) => `${API_ROUTE}/account/public/${cardId}`;
export const USER_PROFILE = API_ROUTE + '/account';
export const USER_AVATAR = (id?: string) => API_ROUTE + `/account/${id}/avatar`;
export const USER_ADDRESS = (id?: string) => `${API_ROUTE}/address/${id}`;
export const USER_FLAG = (countryCode?: string) =>
	`https://www.countryflags.io/${countryCode}/flat/64.png`;

export const ADMIN_USERS = `${API_ROUTE}/admin/user`;
export const ADMIN_USER = (id?: string) => `${API_ROUTE}/admin/user/${id}`;
export const ADMIN_USER_RESET_PASSWORD = (id?: string) =>
	`${API_ROUTE}/admin/user/${id}/resetPassword`;
export const ADMIN_USER_LOGIN = (id?: string) => `${API_ROUTE}/admin/user/login/${id}`;

export const MARKET = `${API_ROUTE}/market`;
export const BUSINESS_UNIT = `${API_ROUTE}/businessUnit`;

export const UPLOAD_AVATAR = `${API_ROUTE}/upload/avatar`;

// export const SUBSCRIPTION = `${API_ROUTE}/subscription`;
// export const SUBSCRIPTION_PLANS = `${API_ROUTE}/subscription/plans`;

export const INVOICES = `${API_ROUTE}/invoice`;
export const INVOICE = (id?: string) => `${API_ROUTE}/invoice/${id}`;
export const INVOICE_PAY_TOKEN = `${API_ROUTE}/invoice/pay/braintreeToken`;
export const INVOICE_PAY = (id?: string) => `${API_ROUTE}/invoice/pay/${id}`;

export const ADDRESSES = `${API_ROUTE}/address`;
export const ADDRESS = (id?: string) => `${API_ROUTE}/address/${id}`;

export const CHECKOUTS = `${API_ROUTE}/checkout`;
export const CHECKOUT = (id?: string) => `${API_ROUTE}/checkout/${id}`;
export const CHECKOUT_BRAINTREE_TOKEN = `${API_ROUTE}/checkout/braintreeToken`;
export const CHECKOUT_COMPLETE = (id?: string) => `${API_ROUTE}/checkout/${id}/complete`;
export const CHECKOUT_RATES = (id?: string) => `${API_ROUTE}/checkout/${id}/rates`;

export const CARD = (id?: string) => `${API_ROUTE}/card/${id}`;
export const CARDS = `${API_ROUTE}/card`;

export const CARD_METRIC = `${API_ROUTE}/card/analytics`;
export const CARD_METRIC_GRAPH = `${API_ROUTE}/card/analytics/graph`;

export const CARD_PUBLIC = (id?: string) => `${API_ROUTE}/card/${id}/public`;
export const CARD_SETUP = (id?: string) => `${API_ROUTE}/card/${id}/setup`;

export const HELP_CATEGORIES = `${API_ROUTE}/help/category`;
export const HELP_CATEGORY = (id?: string) => `${API_ROUTE}/help/category/${id}`;
export const HELP_ARTICLES = `${API_ROUTE}/help/article`;
export const HELP_ARTICLE = (id?: string) => `${API_ROUTE}/help/article/${id}`;
export const FUZZY_SEARCH = (search?: string) => `${API_ROUTE}/help/article/fuzzy/${search}`;

export const ADMIN_CARDS = `${API_ROUTE}/admin/card`;
export const ADMIN_CARD = (id?: string) => `${API_ROUTE}/admin/card/${id}`;

export const ADMIN_CHECKOUTS = `${API_ROUTE}/admin/checkout`;
export const ADMIN_CHECKOUT = (id?: string) => `${API_ROUTE}/admin/checkout/${id}`;

export const ADMIN_ADDRESSES = `${API_ROUTE}/admin/address`;
export const ADMIN_ADDRESS = (id?: string) => `${API_ROUTE}/admin/address/${id}`;

export const VCARDS = `${API_ROUTE}/vCard`;
export const VCARD = (id?: string) => `${API_ROUTE}/vCard/${id}`;

export const ADMIN_VCARDS = `${API_ROUTE}/admin/vCard`;
export const ADMIN_VCARD = (id?: string) => `${API_ROUTE}/admin/vCard/${id}`;

export const EXTERNAL_URLS = `${API_ROUTE}/externalUrl`;
export const EXTERNAL_URL = (id?: string) => `${API_ROUTE}/externalUrl/${id}`;

export const ADMIN_EXTERNAL_URLS = `${API_ROUTE}/admin/externalUrl`;
export const ADMIN_EXTERNAL_URL = (id?: string) => `${API_ROUTE}/admin/externalUrl/${id}`;

export const AFFILIATES = `${API_ROUTE}/affiliate`;
export const AFFILIATE = (id?: string) => `${API_ROUTE}/affiliate/${id}`;

export const CONTACTS = `${API_ROUTE}/contact`;
export const CONTACT = (id?: string) => `${API_ROUTE}/contact/${id}`;

// export const DASHBOARD_AFFILIATE = (userId: string) => `${API_ROUTE}/affiliate/${userId}`;
export const DASHBOARD_AFFILIATE = `${API_ROUTE}/affiliate/userId`;
