import React from 'react';
import store from './src/store';
import SnackbarProvider from './src/components/common/Snackbar/Snackbar';
import ThemeProvider from './src/components/Theme';
import { Provider } from 'react-redux';
import { CssBaseline } from '@mui/material';
import { library } from '@fortawesome/fontawesome-svg-core';

import {
	faFacebook,
	faInstagram,
	faGithub,
	faTwitter,
	faTiktok,
	faGoogle,
	faLinkedin,
} from '@fortawesome/free-brands-svg-icons';

import { faBars as barsLight } from '@fortawesome/pro-light-svg-icons';
import {
	faBars as barsSolid,
	faPencil as pencilSolid,
	faSave,
	faMobile as mobileSolid,
	faArrowRight as arrowSolid,
	faEye as eyeSolid,
} from '@fortawesome/free-solid-svg-icons';

library.add(
	faFacebook,
	faInstagram,
	faGithub,
	faTwitter,
	faTiktok,
	faTwitter,
	faGoogle,
	faLinkedin,
	barsLight,
	barsSolid,
	pencilSolid,
	faSave,
	mobileSolid,
	arrowSolid,
	eyeSolid
);

if (process.env.NODE_ENV === 'production') {
	// ReactGA.initialize('UA-000000000-0');
	// ReactGA.pageview(window.location.pathname + window.location.search);
}

// export const onInitialClientRender = () => {
// 	ReactPixel.init('2490931104537098');
// }

export const wrapRootElement = ({ element }) => {
	return <Provider store={store}>{element}</Provider>;
};

export const wrapPageElement = ({ element, props }) => {
	// props provide same data to Layout as Page element will get
	// including location, data, etc - you don't need to pass it
	return (
		<ThemeProvider location={props.location}>
			<SnackbarProvider>
				<CssBaseline />
				{element}
			</SnackbarProvider>
		</ThemeProvider>
	);
};
export const onRouteUpdate = ({ location, prevLocation }) => {
	// ReactPixel.pageView(); // For tracking page view
	// if (process.env.NODE_ENV === 'production') {
	//     ReactGA.pageview(window.location.pathname + window.location.search);
	// }
};
