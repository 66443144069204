import React from 'react';
import styles from './styles';
import {
	AppBar,
	Button,
	Container,
	Grid,
	Toolbar,
	Typography,
	Link,
	IconButton,
	Box,
	Stack,
	useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material';
import { Link as RouterLink } from 'gatsby';
import { useDispatch, useSelector } from 'react-redux';
import LogoBlack from '@assets/logo/logo-black.inline.svg';
import LogoWhite from '@assets/logo/logo-white.inline.svg';
import SideMenu from '@components/SideMenu';
import { useLocation } from '@reach/router';
import { navigationLandingOpen, navigationOpen } from '@store/navigation/actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Header: React.FC<{ absolute?: boolean; innerRef?: any; inversed?: boolean }> = ({
	absolute,
	innerRef,
	inversed,
}) => {
	const theme = useTheme();
	const { user, navigation } = useSelector(({ user, darkMode, navigation }) => ({
		user,
		darkMode,
		navigation,
	}));

	const location = useLocation();
	const md = useMediaQuery(theme.breakpoints.up('md'));
	const dispatch = useDispatch();

	return (
		<div
			ref={innerRef}
			css={styles(theme)}
			className={absolute ? 'header-wrapper-absolute' : ''}
		>
			<AppBar
				position="static"
				color="transparent"
				className={`header ${inversed ? 'header-inversed' : ''}`}
				sx={{
					backgroundColor: location.pathname.includes('/help')
						? theme.palette.primary.main
						: '',
				}}
			>
				<Toolbar>
					<Container>
						<Grid
							justifyContent="space-between"
							container
							spacing={8}
							alignItems="center"
							sx={{
								backgroundColor:
									location.pathname === '/help' ? theme.palette.primary.main : '',
							}}
						>
							<Grid item xs>
								<RouterLink to="/">
									<Typography
										className="header-logo"
										component="h1"
										variant="h6"
										noWrap
									>
										{inversed || location.pathname.includes('/help') ? (
											<LogoWhite />
										) : (
											<LogoBlack />
										)}
										<span
											style={{
												color:
													inversed || location.pathname.includes('/help')
														? '#fff'
														: '#000',
											}}
										>
											Wave Card
										</span>
									</Typography>
								</RouterLink>
							</Grid>

							<Grid item xs className="navigation">
								<Stack
									direction="row"
									alignItems="center"
									justifyContent="flex-end"
									spacing={8}
								>
									{md && (
										<Stack
											direction="row"
											spacing={6}
											alignItems="center"
											justifyContent="flex-end"
											sx={{ flexGrow: 1 }}
										>
											<Link
												className="navigation-item-link"
												component={RouterLink}
												to="/checkout"
												sx={{
													color:
														location.pathname.includes('/pricing') ||
														location.pathname.includes('/affiliate')
															? theme.palette.common.black
															: theme.palette.common.white,
												}}
											>
												Create
											</Link>
											<Link
												className="navigation-item-link"
												component={RouterLink}
												to="/pricing"
												sx={{
													color:
														location.pathname.includes('/pricing') ||
														location.pathname.includes('/affiliate')
															? theme.palette.common.black
															: theme.palette.common.white,
												}}
											>
												Pricing
											</Link>
											<Link
												className="navigation-item-link"
												component={RouterLink}
												to="/help"
												sx={{
													color:
														location.pathname.includes('/pricing') ||
														location.pathname.includes('/affiliate')
															? theme.palette.common.black
															: theme.palette.common.white,
												}}
											>
												Help
											</Link>
										</Stack>
									)}
									<Box>
										<Stack direction="row" spacing={2} alignItems="center">
											{!!user ? (
												<SideMenu user={user} navigation={navigation} />
											) : (
												<Button
													to="/login"
													component={RouterLink}
													variant={
														!inversed && theme.palette.mode === 'dark'
															? 'contained'
															: 'dark'
													}
													className="button"
												>
													Sign in
												</Button>
											)}

											{!md && (
												<IconButton
													onClick={() =>
														dispatch(navigationLandingOpen(true))
													}
													sx={{ height: 50, width: 50 }}
												>
													<FontAwesomeIcon
														icon={['fas', 'bars']}
														color={
															location?.pathname?.includes('pricing')
																? theme.palette.common.black
																: theme.palette.common.white
														}
														size="1x"
													/>
												</IconButton>
											)}
										</Stack>
									</Box>
								</Stack>
							</Grid>
						</Grid>
					</Container>
				</Toolbar>
			</AppBar>
		</div>
	);
};

export default React.forwardRef((props, ref) => <Header {...props} innerRef={ref} />);
