import React, { useContext } from 'react';
import styles from './styles';
import Layout from '@components/common/Layouts/Landing';
import { useTheme } from '@mui/material';
import Features from '@views/Home/Features';
import { Box, Button, Container, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import { Link } from 'gatsby';
import Phone from '@assets/phone-preview.png';
import Card from '@assets/card-black.svg';
import MultiCardHero from '@components/MultiCardHero';
import DashboardPreview from '@components/Landing/Home/DashboardPreview';
import CardDemo from '@components/Landing/common/CardDemo';
import AffiliateJoinCTA from '@components/AffiliateJoinCTA';
import CTABackground from '@assets/cta-bg.png';
import CTAContent from '@assets/card-tap.png';
import { HeaderHeightContext } from '@helpers/contexts';
import alphaImage from '@assets/pricing-mobile.png';
import background from '@assets/bg.png';
import heroPhone from '@assets/wc-phone@2x.png';

const Landing: React.FC = () => {
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down('sm'));
	const laptop = useMediaQuery(theme.breakpoints.down('md'));
	const phone = useMediaQuery(theme.breakpoints.down('lg'));

	const { height: headerHeight } = useContext(HeaderHeightContext);

	return (
		<div css={styles(theme)}>
			<Box
				sx={{ paddingTop: `${headerHeight}px` /*backgroundImage: `url("${background}")`*/ }}
				className="hero-container"
			>
				<Container maxWidth="md" sx={{ height: '100%' }}>
					<Grid
						container
						direction="column"
						justifyContent="center"
						alignItems="center"
						sx={{ height: '100%' }}
					>
						<Grid
							item
							md={12}
							lg={6}
							sx={{ position: 'relative', display: 'contents' }}
						>
							<div>
								<Stack
									direction="column"
									spacing={6}
									className="hero-text-wrapper"
									sx={{
										color: '#fff',
										width: '80%',
										zIndex: 10,
									}}
								>
									<Typography
										sx={{ fontSize: '1.5rem' }}
										variant="h1"
										className="text-align"
									>
										One single tap, a wave of connections
									</Typography>
									<Typography
										sx={{ fontSize: '1rem' }}
										className="text-align"
										variant="body1"
									>
										Welcome to the neo-business card. Whether it’s ensuring to
										leave the best impression, tracking your network, or
										communicating an identity. Wave Card ensures you leave
										remembered.
									</Typography>
									<div>
										<Stack spacing={2} className="button-container">
											<Button
												variant="inversed"
												color="primary"
												component={Link}
												to="/checkout"
												disableElevation
												className="hero-button"
											>
												Buy now
											</Button>

											<Button
												variant="contained"
												component={Link}
												to="/pricing"
												disableElevation
												className="hero-button"
											>
												Find out more
											</Button>
										</Stack>
									</div>
								</Stack>
							</div>
						</Grid>
						<Grid item md={12} lg={6} sx={{ display: 'contents' }}>
							{/* {!phone ? <img src={Phone} className="hero-phone" /> : null} */}
							<img src={heroPhone} alt="phone" width="600" className="hero-phone" />
						</Grid>
					</Grid>
				</Container>
			</Box>
			<Container maxWidth="md" sx={{ maxWidth: phone && '800px' }}>
				<Box sx={{ mt: 10 }}>
					<Typography
						variant="caption"
						display="block"
						gutterBottom
						align="center"
						sx={{ color: '#2962FF' }}
					>
						Disclaimer
					</Typography>
					<Typography variant="h2" display="block" align="center">
						What does Alpha mean?
					</Typography>
				</Box>

				<Grid
					container
					justifyContent="space-between"
					alignItems="center"
					direction="row"
					className="alpha-container"
				>
					<Grid
						item
						container
						justifyContent="flex-start"
						alignItems="flex-start"
						direction="column"
						className="alpha-container-item"
						md={12}
						lg={10}
					>
						<Typography className="alpha-heading" variant="h4">
							Help Wave Card develop faster with the features you want...
						</Typography>
						<Typography className="alpha-body" variant="body2">
							Alpha means that Wave Card is in its early production phase, meaning
							that the software may have bugs, lack of features and the physical
							product may be different to the final release item. If you feel
							passionate about Wave Card please consider buying one of our cards at a
							discounted price and provide feedback on the platform so we can
							prioritise the features you want to see.
						</Typography>
						<Button
							variant="contained"
							color="primary"
							className="alpha-btn"
							component={Link}
							to="/help/alpha/what-is-alpha"
						>
							Read more
						</Button>
					</Grid>
					<Grid
						item
						container
						justifyContent="center"
						alignItems="center"
						className="alpha-image-container"
					>
						<img className="alpha-image" src={alphaImage} alt="pricing-mobile" />
					</Grid>
				</Grid>
			</Container>

			<Container maxWidth="md">
				<Box sx={{ mt: 20 }}>
					<Features
						caption="Rethinking what a business card does"
						title="Organise your networking with a tap"
					/>
				</Box>
			</Container>

			<Box sx={{ mt: 10 }} />

			<MultiCardHero
				caption="ROCK, PAPER, METAL..."
				title="316 Brushed Stainless Steel"
				description="The stainless steel used on Wave Card is heavy at 80 grams; it is corrosion resistant and cannot be scratched easily. A selection of three elegant cards, electroplated in gold, black or left unfinished. The quality of Wave Card is durable and long-lasting."
			/>
			<DashboardPreview
				caption="Track, change and measure"
				title="Control your card on our dashboard"
				description="Our dashboard allows to have a visual overview of the cards in your ownership."
			/>
			<Box sx={{ mt: 20 }}>
				<CardDemo caption="Try out how our card looks" title="How does it look on you?" />
			</Box>
			<Box sx={{ my: 20 }}>
				<Container maxWidth="md">
					<AffiliateJoinCTA
						backgroundImage={CTABackground}
						backgroundContent={CTAContent}
						description="Start earning from your network in more ways than one."
						buttonLink="affiliate"
						buttonText="Create"
					/>
				</Container>
			</Box>
		</div>
	);
};

export default () => (
	<Layout flush disableGutterTop HeaderProps={{ inversed: true }}>
		<Landing />
	</Layout>
);
