import { css } from '@emotion/react';

export default (theme) => css`
	.header-logo {
		display: flex;
		align-items: center;

		svg {
			width: 40px;
			height: auto;
		}

		span {
			margin-left: ${theme.spacing(2)};
			text-transform: uppercase;
			font-weight: 700;
			letter-spacing: 0.12em;
			color: ${theme.palette.mode === 'light'
				? theme.palette.common.black
				: theme.palette.common.white};
		}
	}
`;
