import React from 'react';
import styles from './styles';
import { Link as RouterLink } from 'gatsby';
import { Box, List, ListItem, ListItemIcon, ListItemText, useTheme } from '@mui/material';
import { CreditCard, CurrencyPound, Help } from '@mui/icons-material';
import { useLocation } from '@reach/router';
import { navigationLandingOpen } from '@store/navigation/actions';
import { useDispatch } from 'react-redux';

const navList = [
	{ name: 'create', icon: <CreditCard />, link: '/checkout' },
	{ name: 'pricing', icon: <CurrencyPound />, link: '/pricing' },
	{ name: 'help', icon: <Help />, link: '/help' },
];

const Navigation: React.FC = () => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const location = useLocation();
	const currentRoute = location?.pathname;

	return (
		<Box css={styles(theme)} role="presentation" className="navigation">
			<List>
				{navList?.map(({ name, icon, link }) => (
					<ListItem
						className={`navigation-item ${
							currentRoute?.includes(link) ? 'navigation-item-active' : ''
						}`}
						key={name}
						button
						disableGutters
						onClick={() => dispatch(navigationLandingOpen(false))}
						component={RouterLink}
						to={link}
					>
						<ListItemIcon className="navigation-item-icon">{icon}</ListItemIcon>
						<ListItemText
							classes={{
								primary: 'navigation-item-text-primary',
								secondary: 'navigation-item-text-secondary',
							}}
							primary={name}
						/>
					</ListItem>
				))}
			</List>
		</Box>
	);
};

export default Navigation;
