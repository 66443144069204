import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useTheme } from '@mui/styles';
import { useDispatch, useSelector } from 'react-redux';
import { UserState } from '@store/user/types';
import { NavigationState } from '@store/navigation/types';
import { readCookie } from '@helpers/storage';
import { logoutUser } from '@store/user/actions';
import { CssBaseline } from '@mui/material';
import { Global } from '@emotion/react';
import globalStyles from '@globalStyles';
import styles from './styles';
import Header from '@components/common/Layouts/Landing/Header';
import Footer from '@components/common/Layouts/Landing/Footer';
import { HeaderHeightContext } from '@helpers/contexts';
import Sidebar from '@components/common/Layouts/Landing/Sidebar';

const Landing: React.FC<{
	HeaderProps?: {
		inversed?: boolean;
		absolute?: boolean;
	};
	disableGutterTop?: boolean;
	disableGutterBottom?: boolean;
	flush?: boolean;
	center?: boolean;
}> = ({ HeaderProps, children, center, flush, disableGutterTop, disableGutterBottom }) => {
	const theme = useTheme();
	const dispatch = useDispatch();
	const $header: any = useRef(null);
	const { user } = useSelector(({ user, navigation }) => ({
		user,
		navigation,
	}));
	const [headerHeight, setHeaderHeight] = useState(null);
	const isBrowser = typeof window !== 'undefined';

	const handleHeaderHeight = () => {
		setHeaderHeight($header?.current?.offsetHeight ?? null);
	};

	useEffect(() => {
		if (isBrowser) {
			window.addEventListener('resize', handleHeaderHeight);
		}
		handleHeaderHeight();

		return () => {
			window.removeEventListener('resize', handleHeaderHeight);
		};
	}, []);

	useEffect(() => {
		// if user exists but token or refresh token is missing, log the user out
		if (!!user && (!readCookie('user_token') || !readCookie('user_refresh_token'))) {
			dispatch(logoutUser());
		}
	}, []);

	return (
		<HeaderHeightContext.Provider value={{ height: headerHeight }}>
			<Global styles={globalStyles(theme)} />
			<div css={styles(theme)} className={`layout ${center ? 'layout-center' : ''}`}>
				<Header ref={$header} {...(HeaderProps ?? {})} />
				<Sidebar anchor="left" />
				<main
					className="content-wrapper"
					style={
						flush
							? {
									marginTop: `-${headerHeight}px`,
							  }
							: undefined
					}
				>
					<div
						className={`content ${
							disableGutterTop ? 'content-disable-gutter-top' : ''
						} ${disableGutterBottom ? 'content-disable-gutter-bottom' : ''}`}
					>
						{children}
					</div>
					<Footer />
				</main>
			</div>
		</HeaderHeightContext.Provider>
	);
};

export default Landing;
