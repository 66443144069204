import React from 'react';
import styles from './styles';
import { Avatar, Box, Button, Divider, Drawer, Link, Typography, useTheme } from '@mui/material';
import { NightsStay as DarkMode, WbSunny as LightMode } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';
import { navigationLandingOpen } from '@store/navigation/actions';
import Scrollbar from '@components/ScrollBar';
import { Link as RouterLink } from 'gatsby';
import LogoWhite from '@assets/logo/logo-white.inline.svg';
import LogoBlack from '@assets/logo/logo-black.inline.svg';
import { USER_AVATAR } from '@helpers/api';
import Navigation from '@components/common/Layouts/Landing/Navigation';
import { toggleDarkMode } from '@store/darkMode/actions';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

const DRAWER_WIDTH = 280;

const Sidebar: React.FC<{ anchor: Anchor }> = ({ anchor }) => {
	const theme = useTheme();
	const dispatch = useDispatch();

	const { user, darkMode, navigation } = useSelector(({ user, darkMode, navigation }) => ({
		user,
		darkMode,
		navigation,
	}));

	const renderContent = (
		<Scrollbar
			sx={{
				height: '100%',
				'& .simplebar-content': {
					height: '100%',
					display: 'flex',
					flexDirection: 'column',
				},
			}}
			css={styles(theme)}
		>
			<Box sx={{ px: 2.5, py: 3, pl: 5 }}>
				<Box component={RouterLink} to="/" sx={{ display: 'inline-flex' }}>
					<Typography className="header-logo" variant="h6" noWrap>
						{darkMode ? <LogoWhite /> : <LogoBlack />}
						<span>Wave card</span>
					</Typography>
				</Box>
			</Box>

			{!!user && (
				<Box sx={{ mb: 2, mx: 2.5 }}>
					<Link underline="none" component={RouterLink} to="/dashboard/account">
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								padding: theme.spacing(2, 2.5),
								borderRadius: theme.shape.borderRadiusSm,
								backgroundColor: theme.palette.grey[500_12],
							}}
						>
							<Avatar src={USER_AVATAR(user?._id)} alt={user?.firstName} />
							<Box sx={{ ml: 2 }}>
								<Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
									{user?.firstName}
								</Typography>
								<Typography variant="body2" sx={{ color: 'text.secondary' }}>
									{user?.permissions?.isAdmin ? 'Admin' : 'User'}
								</Typography>
							</Box>
						</Box>
					</Link>
				</Box>
			)}

			<Navigation />

			<Box sx={{ flexGrow: 1 }} />

			<Divider sx={{ mt: 2 }} />
			<Box sx={{ px: 2.5, py: 2 }}>
				<Button
					color="secondary"
					variant="outlined"
					fullWidth
					startIcon={
						(darkMode === null ? false : !darkMode) ? <DarkMode /> : <LightMode />
					}
					onClick={(e) => {
						e?.preventDefault?.();
						dispatch(toggleDarkMode(darkMode === null ? false : !darkMode));
					}}
				>
					{(darkMode === null ? false : !darkMode) ? 'Dark mode' : 'Light mode'}
				</Button>
			</Box>
		</Scrollbar>
	);

	return (
		<Drawer
			anchor={anchor}
			open={!!navigation?.open_landing}
			PaperProps={{
				sx: { width: DRAWER_WIDTH, backgroundColor: theme.palette.background.paper },
			}}
			onClose={() => dispatch(navigationLandingOpen(false))}
		>
			{renderContent}
		</Drawer>
	);
};

export default Sidebar;
